import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";
import { useUserContext } from "./UserContextProvider";
import { refreshMonitorData } from "../data/messageNotifierServices";

const ConversationContext = createContext();

export const ConversationContextProvider = ({ children }) => {
  const { setIsLoading } = useUserContext();
  const baseUrl = appSettings.serverBaseUrl;
  const [socketConnection, setSocketConnection] = useState();

  const [recentContactsList, setRecentContactsList] = useState([]);
  const [chatContact, setChatContact] = useState("");
  const [selectedChatContactToShow, setSelectedChatContactToShow] =
    useState("");
  const [isShowChatHistory, setChatHistory] = useState(false);
  const [messageNotificationCount, setMessageNotificationCount] = useState(0);
  const [campaignRepliedStatuses, setCampaignRepliedStatuses] = useState([]);

  const [chatData, setChatData] = useState();
  const [chatImage, setChatImage] = useState();
  const [quickReplyList, setQuickReplyList] = useState();
  const [pendingApprovalQuickReply, setPendingApprovalQuickReply] = useState();
  const [notificationList, setNotificationList] = useState();

  const convGetChatContacts = (searchText) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/get-chat-contacts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchText }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          if (response?.recentContacts.length > 0) {
            setRecentContactsList(response.recentContacts);
          } else {
            setRecentContactsList([{ Empty: true }]);
          }
        }
      })
      .catch((err) => {
        toast.error("Couldn't fetch recent chat contacts");
      });
    setIsLoading(false);
  };

  const convGetAssignedChats = async (searchText) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/conv/get-assigned-chats`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ searchText }),
      });
      const assignChatData = await response.json();
      setIsLoading(false);
      return assignChatData;
    } catch (err) {
      console.log("Error in convGetChatAssigns ", err);
      return [];
    }
  };

  const convGetChatHistory = (phoneNumber) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/get-chat-history`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          setChatData(JSON.parse(response));
          refreshMonitorData?.next("refreshRecentChat");
        }
      })
      .catch((err) => {
        toast.error("Failed to retrieve chat history");
      });
    setIsLoading(false);
  };

  const updateMessageReadStatus = (phoneNumber) => {
    const readStatus = false;
    setIsLoading(true);
    fetch(`${baseUrl}/conv/update-message-read-status`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber, readStatus }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          refreshMonitorData?.next("refreshRecentChat");
        }
      })
      .catch((err) => {
        toast.error("Failed to update read status");
      });
    setIsLoading(false);
  };

  const convGetChatMedia = (mediaId) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/get-chat-media`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ mediaId }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.extractedMedia) {
          if (response?.mediaContentType?.includes("image")) {
            setChatImage(
              `data:${response.mediaContentType};base64,${response.extractedMedia}`
            );
          } else {
            const date = new Date();
            const currentTime = `${date.getDate()}${date.getHours()}${date.getMinutes()}`;

            const downloadLink = document.createElement("a");
            downloadLink.href = `data:${response.mediaContentType};base64,${response.extractedMedia}`;
            downloadLink.download = `wabm-download-${currentTime}`;
            downloadLink.click();
          }
        } else {
          toast.warn("Media unavailable");
        }
      })
      .catch((err) => {
        toast.error("Failed to fetch media");
      });
  };

  const getCampaignRepliedStatuses = () => {
    fetch(`${baseUrl}/conv/get-campaign-replied-statuses`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          setCampaignRepliedStatuses(response);
        }
      })
      .catch((err) => {
        // toast.error("Couldn't fetch campaign statuses");
        console.log("Couldn't fetch campaign statuses");
      });
  };

  const saveQuickReply = (quickReplyData) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/save-quick-reply`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(quickReplyData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
          getQuickReply();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to save quick reply");
        setIsLoading(false);
      });
  };

  const getQuickReply = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/get-quick-reply`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
          setIsLoading(false);
        }
        setQuickReplyList(response);
      })
      .catch((err) => {
        toast.error("Unable to get quick reply");
        setIsLoading(false);
      });
  };

  const getPendingApproval = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/get-pending-approval`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
          setIsLoading(false);
        }
        setPendingApprovalQuickReply(response);
      })
      .catch((err) => {
        toast.error("Unable to get pending approval quick reply");
        setIsLoading(false);
      });
  };

  const approveQuickReply = (approveQuickReplyData) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/approve-quick-reply`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(approveQuickReplyData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to approve quick reply");
        setIsLoading(false);
      });
  };

  const deleteQuickReply = (quickReplyId) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/delete-quick-reply`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: quickReplyId }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to delete quick reply");
        setIsLoading(false);
      });
  };

  const assignChat = (phoneNumber, assignedUsersArray) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/assign-chats`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: phoneNumber,
        assignedUser: assignedUsersArray,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          convGetChatContacts("");
          toast.success(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to Assign chat");
      });
    setIsLoading(false);
  };

  const getNotifications = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/get-notifications`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Error) {
          toast.error(response.Error);
          setIsLoading(false);
        }
        setNotificationList(response);
      })
      .catch((err) => {
        toast.error("Unable to get notifications");
        setIsLoading(false);
      });
  };

  const saveWelcomeMessage = (welcomeMessages) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/create-welcome-message`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(welcomeMessages),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
          getWelcomeMessages();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to save welcome message");
        setIsLoading(false);
      });
  };

  const deleteWelcomeMessage = (welcomeMessageId) => {
    setIsLoading(true);
    fetch(`${baseUrl}/conv/delete-welcome-message`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messageId: welcomeMessageId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else if (response?.message) {
          toast.success(response.message);
          getWelcomeMessages();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to delete welcome message");
        setIsLoading(false);
      });
  };

  const changeWelcomeMessagesOrder = async (welcomeMessage) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/conv/change-welcome-messages-order`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ welcomeMessage }),
        }
      );
      const result = await response.json();
      if (result?.Error) {
        toast.error(result.Error);
      } else if (result?.message) {
        toast.success(result.message);
        getWelcomeMessages();
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to change welcome message order");
    } finally {
      setIsLoading(false);
    }
  };

  const getWelcomeMessages = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/conv/fetch-welcome-messages`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const welcomeMessages = await response.json();
      setIsLoading(false);
      return welcomeMessages;
    } catch (err) {
      setIsLoading(false);
      console.log("getWelcomeMessages err ", err);
      return [];
    }
  };

  const saveMessageFlowDesign = (messageFlow) => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/save-message-flow-design`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ messageFlow }),
    })
      .then((res) => res.json())
      .then((response) => {
        toast.success(response?.message);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.Error);
        setIsLoading(false);
      });
  };

  return (
    <ConversationContext.Provider
      value={{
        convGetChatContacts,
        convGetChatHistory,
        convGetChatMedia,
        getCampaignRepliedStatuses,
        recentContactsList,
        chatContact,
        selectedChatContactToShow,
        isShowChatHistory,
        messageNotificationCount,
        campaignRepliedStatuses,
        chatData,
        chatImage,
        socketConnection,
        setChatContact,
        setSelectedChatContactToShow,
        setChatImage,
        setSocketConnection,
        setChatHistory,
        setMessageNotificationCount,
        quickReplyList,
        pendingApprovalQuickReply,
        notificationList,
        saveQuickReply,
        getQuickReply,
        deleteQuickReply,
        getPendingApproval,
        approveQuickReply,
        assignChat,
        getNotifications,
        convGetAssignedChats,
        saveWelcomeMessage,
        getWelcomeMessages,
        updateMessageReadStatus,
        saveMessageFlowDesign,
        deleteWelcomeMessage,
        changeWelcomeMessagesOrder,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export const useConversationContext = () => useContext(ConversationContext);
