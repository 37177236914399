import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { MdDeleteOutline } from "react-icons/md";
import {
  Drawer,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";
import { HiArrowDownCircle } from "react-icons/hi2";
import Popup from "reactjs-popup";

import { useUserContext } from "../../contexts/UserContextProvider";
import { Loader, CreateAgent, SeoTags } from "..";
import { colorsConfig } from "../../config/colorsConfig";

const AgentManagement = () => {
  const {
    isLoading,
    apiCallGetUserAgents,
    userAgentsList,
    apiCallUpdateAgent,
    apiCallDeleteAgent,
    currentUserInfo,
  } = useUserContext();

  const [toCreateAgent, setToCreateAgent] = useState(false);
  const [changeUserType, setChangeUserType] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [agentType, setAgentType] = useState("Agent");
  const [agentIdValue, setAgentIdValue] = useState(false);

  const toggleAgentStatusOnChange = (agentId, infoType, infoValue) => {
    apiCallUpdateAgent(agentId, infoType, infoValue);
  };

  const agentTypeOnChange = () => {
    apiCallUpdateAgent(
      agentIdValue,
      "agent_isAdmin",
      agentType === "Agent" ? 0 : 1
    );
    setIsReload(true);
    setChangeUserType(false);
  };

  const handleDeleteOnClick = (agentId) => {
    apiCallDeleteAgent(agentId);
    setIsReload(true);
  };

  const agentTableColumns = [
    {
      name: "Agent User Name",
      selector: (row) => row?.agentUsername,
      sortable: true,
    },
    {
      name: "Agent Name",
      selector: (row) => row?.agentFullName,
      sortable: true,
    },
    {
      name: "Agent Email",
      selector: (row) => row?.agentEmail,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => {
        if (row?.isAdmin === 0) {
          return "Agent";
        } else if (row?.isAdmin === 1) {
          return "Admin";
        } else {
          return "SuperAdmin";
        }
      },
      maxWidth: "150px",
      sortable: true,
      center: true,
    },
    {
      name: "Enable/Disable",
      maxWidth: "150px",
      selector: (row) => {
        if (row?.isAdmin === 1 || row?.isAdmin === 0) {
          return (
            <input
              type="checkbox"
              role="switch"
              onChange={() =>
                toggleAgentStatusOnChange(
                  row?.agentId,
                  "agent_isBlocked",
                  !row?.isBlocked
                )
              }
              checked={row?.isBlocked === 0 ? true : false}
              className="my-toggle-switch"
            />
          );
        } else {
          return <span></span>;
        }
      },
      center: true,
    },
    {
      name: "Action",
      maxWidth: "200px",
      selector: (row) => {
        if (row?.isAdmin === 0 || row?.isAdmin === 1) {
          return (
            <div className="flex gap-3 w-96">
              <Menu>
                <MenuHandler>
                  <IconButton variant="text">
                    <HiArrowDownCircle className="h-7 w-7" />
                  </IconButton>
                </MenuHandler>
                <MenuList>
                  <MenuItem>
                    <Popup
                      trigger={
                        <Button color="red" className="w-36">
                          Delete User
                        </Button>
                      }
                      position="left bottom"
                      className="hidden"
                    >
                      <div className="my-popup-style-1">
                        <p>
                          Are you sure you want to delete "{row?.agentUsername}
                          "?
                        </p>
                        <button
                          type="button"
                          onClick={() => handleDeleteOnClick(row?.agentId)}
                          className="my-button-style-cancel mt-2 flex items-center gap-1 text-sm"
                        >
                          <MdDeleteOutline size="0.8rem" />
                          Delete Agent
                        </button>
                      </div>
                    </Popup>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      type="button"
                      color="green"
                      onClick={() => {
                        setAgentIdValue(row?.agentId);
                        setChangeUserType(true);
                      }}
                      className="w-36"
                    >
                      Change User Type
                    </Button>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          );
        } else {
          return <span></span>;
        }
      },
      grow: 0,
    },
  ];

  useEffect(() => {
    apiCallGetUserAgents();
    setIsReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  return (
    <>
      <SeoTags title="Agents ‣ WaBM" />

      <Drawer
        placement="right"
        open={toCreateAgent}
        onClose={() => setToCreateAgent(false)}
        size="500px"
        className="w-full p-4 z-[9996]"
      >
        <CreateAgent
          setToCreateAgent={setToCreateAgent}
          callbackCreateContact={() => setToCreateAgent(false)}
        />
      </Drawer>
      <Drawer
        open={changeUserType}
        onClose={() => setChangeUserType(false)}
        placement="right"
        size="400px"
        className="w-full p-4 pr-9 z-[9996]"
      >
        <div className="flex items-center justify-between px-4 pb-2">
          <Typography variant="h5" color="blue-gray">
            Change Type
          </Typography>
        </div>
        <form className="flex flex-col gap-6 p-4">
          <Select
            label="User Type"
            variant="standard"
            color="blue"
            value={agentType}
            required
            onChange={(e) => setAgentType(e)}
            className="my-input-box-1"
          >
            <Option
              key="Agent"
              value="Agent"
              className={`hover:bg-blue-300 focus:bg-blue-300 ${
                agentType === "Agent" ? "bg-wabmGreen" : ""
              }`}
            >
              Agent
            </Option>
            {currentUserInfo?.isAdmin === 2 ? (
              <Option
                key="Admin"
                value="Admin"
                className={`hover:bg-blue-300 focus:bg-blue-300 ${
                  agentType === "Admin" ? "bg-wabmGreen" : ""
                }`}
              >
                Admin
              </Option>
            ) : (
              <></>
            )}
          </Select>
          <Button
            onClick={() => agentTypeOnChange()}
            variant="outlined"
            color="green"
          >
            Update
          </Button>
        </form>
      </Drawer>

      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Agent Management</h1>
          <h3>
            Create, Delete and Manage Agents information, their permissions and
            priveleges.
          </h3>
        </div>

        <Button
          type="button"
          variant="gradient"
          color="blue"
          onClick={() => setToCreateAgent(true)}
        >
          Create a New Agent
        </Button>

        <div className="wbm-card-3 space-y-3">
          <h2 className="font-Montserrat text-lg">Active Agents</h2>
          <div className="rounded-md overflow-auto">
            {isLoading ? (
              <Loader />
            ) : userAgentsList?.length > 0 ? (
              <DataTable
                columns={agentTableColumns}
                data={userAgentsList?.filter((x) => x.isBlocked === 0)}
                customStyles={agentTableStyles}
                fixedHeader
                fixedHeaderScrollHeight="60vh"
              />
            ) : (
              <div className="space-y-3">
                <p>No Active Agent found.</p>
              </div>
            )}
          </div>
        </div>

        <div className="wbm-card-3 space-y-3">
          <h2 className="font-Montserrat text-lg">Inactive Agents</h2>
          <div className="rounded-md overflow-auto">
            {isLoading ? (
              <Loader />
            ) : userAgentsList.length > 0 ? (
              <div className="my-5 space-y-2 rounded-md">
                <DataTable
                  columns={agentTableColumns}
                  data={userAgentsList?.filter((x) => x.isBlocked === 1)}
                  customStyles={agentTableStyles}
                  fixedHeader
                  fixedHeaderScrollHeight="60vh"
                />
              </div>
            ) : (
              <div className="space-y-3">
                <p>No Active Agent found.</p>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};
export default AgentManagement;

const agentTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
